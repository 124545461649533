@import "./shared.scss";

h2.followme-form-subtitle {
  text-transform: none;
  color: #7a7786;
  font-size: 1.5rem;
  line-height: 25px;
  margin-bottom: 50px;
}

.followme-form-label {
  color: #7a7786;
  font-weight: 600;
}

.followme-form-row {
  margin-top: 30px;
}

.followme-form-input,
input.followme-form-input,
select.followme-form-input {
  padding: 0 25px;
  border-radius: 40px;
  margin-bottom: 10px;
  height: 40px;
}

select.followme-form-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 0 25px;
  height: 40px;
  background: url("../img/arrow.svg") 93% / 2.5% no-repeat;
}

select.followme-form-input::-ms-expand {
  display: none; /* Remove default arrow in Internet Explorer 10 and 11 */
}

select.followme-form-input:focus {
  background-image: url("../img/arrow-green.svg");
}

input.followme-form-input:focus,
select.followme-form-input:focus {
  border: 2px solid $brand-primary;
  box-shadow: none;
}

textarea.followme-form-textarea {
  height: 192px;
  border-radius: 16px;
  border: 1px solid hsl(0, 0%, 80%);
  padding: 1.5rem;
  resize: none;
  margin-bottom: 10px;
}

textarea.followme-form-textarea:focus {
  outline: none;
  border: 2px solid $brand-primary;
}

button.followme-form-button {
  width: 100%;
  height: 70px;
  border-radius: 16px !important;
  font-size: 1.2rem;
}

div.followme-form-row:last-of-type {
  margin-top: 0;
}

#vox-assinante-follow-me h2 {
  margin: 50px 0 49px;
}
