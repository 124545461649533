@import "./shared.scss";

.textInfo {
  color: #acabb7;
  font-family: "cera";
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
}

.MuiTimelineItem-missingOppositeContent:before {
  content: none;
  display: none;
}
