@import "./shared.scss";

/* Bootstrap overrides and complements - vpck */

html body,
#root {
  font-family: cera;
  color: $brand-gray;
  height: 100%;
  width: 100%;
  background-color: #fcfcfc;
}

.loading {
  overflow: hidden;
  position: absolute;
  z-index: 9999;

  svg {
    z-index: 99999;
    width: 80px;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
}

.vox-me-name {
  white-space: nowrap;
}

div.vxm-error {
  color: red;
  font-size: 85%;
  width: 100%;

  &.text-left {
    margin-bottom: 5px;
    margin-top: -5px;
    margin-left: 10px;
  }
}

label.vxm-error,
label.vxm-error > a {
  color: red;
  margin-bottom: 0;

  a {
    font-weight: 600;
  }
}

.vox-modal {
  .btn {
    outline: none;
  }

  textarea {
    resize: none;
  }
}

.btn {
  &.btn-light {
    border: 1px solid #999;
  }
}

.navbar {
  .navbar-toggler-icon::before {
    position: relative;
    top: 3px;
    content: "\f0c9";
    font: 25px "FontAwesome";
    color: $brand-primary;
  }

  .navbar-nav.navbar-center {
    position: absolute;
    left: 50%;
    transform: translatex(-50%);
  }
}

a {
  color: $brand-primary;
  transition: color 0.4s;

  &:hover {
    color: darken($brand-primary, 10);
    transition: none;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  text-transform: uppercase;
}

input {
  outline: none;
}

.text-success-vox {
  color: $brand-primary;
}
.btn {
  font-weight: 600 !important;
}

.internas {
  .modal-title {
    h5 {
      margin: 16px 0;
      text-align: center;
      font-weight: bold;
      font-size: 20px;
      line-height: 24px;
      color: #33303e;
    }

    h6 {
      font-weight: bold;
      font-size: 14px;
      line-height: 24px;
      color: #5f5c6b;
      margin: 0;
    }
  }

  .modal-inputs {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  .modal-checkboxes {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 16px 0;

    div {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    input[type="checkbox"] {
      position: absolute;
      z-index: -1;
      opacity: 0;
    }

    input[type="checkbox"] + label {
      position: relative;
      cursor: pointer;
      padding-left: 10px;
      padding-bottom: 4px;
      color: #c6c5ce;
      z-index: 11;
      font-weight: bold;
    }

    input[type="checkbox"] + label::before {
      content: "";
      position: absolute;
      width: 32px;
      height: 32px;
      left: 0;
      bottom: 0;
      border: 1px solid #c6c5ce;
      border-radius: 8px;
      vertical-align: bottom;
    }

    input[type="checkbox"]:checked + label::after {
      content: "";
      position: absolute;
      width: 32px;
      height: 32px;
      left: 0;
      bottom: 0;
      border: 1px solid #4e4b59;
      border-radius: 8px;
      background-color: #4e4b59;
      z-index: -1;
    }

    input[type="checkbox"]:checked + label {
      color: #fff;
      z-index: 11;
    }
  }

  .modal-buttons {
    display: flex;
    justify-content: flex-end;

    > button {
      margin-right: 8px;
    }
  }

  .alert-message {
    color: #c53030;
  }

  .btn {
    padding: 6px 18px;
    border-radius: 100px;
    text-transform: uppercase;
    font-weight: 600 !important;

    &:not(.btn-light) {
      border: none;
    }

    &.btn-primary:not(:disabled):not(.disabled) {
      background-color: $brand-primary;
      color: $button-text-color;

      &:hover {
        background-color: darken($brand-primary, 5);
      }

      &:active,
      .active {
        background-color: lighten($brand-primary, 20);
      }

      &:focus {
        box-shadow: 0 0 0 0.2rem lighten($brand-primary, 10);
      }
    }

    &.btn-outline-primary,
    &.btn-outline-secondary,
    &.btn-outline-dark,
    &.btn-outline-danger,
    &.btn-outline-disabled {
      border-width: 1px;
      border-style: solid;
    }

    &.btn-outline-primary {
      border-color: $brand-primary;
      color: $brand-primary;

      &:hover {
        background-color: transparent;
        border-color: darken($brand-primary, 10);
        color: darken($brand-primary, 10);
      }
    }

    &.btn-outline-secondary {
      border-color: $brand-secondary;
      color: $brand-secondary;

      &:hover {
        background-color: transparent;
        border-color: darken($brand-secondary, 10);
        color: darken($brand-secondary, 10);
      }
    }

    &.btn-outline-dark {
      border-color: $brand-gray;
      color: $brand-gray;

      &:hover {
        background-color: transparent;
        border-color: darken($brand-gray, 10);
        color: darken($brand-gray, 10);
      }
    }

    &.btn-outline-danger {
      &:hover {
        background-color: transparent;
        border-color: darken(#dc3545, 10);
        color: darken(#dc3545, 10);
      }
    }

    &.btn-disabled {
      background-color: #ccc;
      opacity: 0.5;
    }

    &.btn-secondary:not(:disabled):not(.disabled) {
      background-color: $brand-secondary;
      color: $button-text-color;

      &:hover {
        background-color: darken($brand-secondary, 5);
      }

      &:active,
      .active {
        background-color: lighten($brand-secondary, 20);
      }

      &:focus {
        box-shadow: 0 0 0 0.2rem lighten($brand-secondary, 10);
      }
    }

    &.btn-dark:not(:disabled):not(.disabled) {
      background-color: $brand-gray;
      color: white;
      font-weight: 600 !important;

      &:hover {
        background-color: darken($brand-gray, 5);
      }

      &:active,
      .active {
        background-color: lighten($brand-gray, 20);
      }

      &:focus {
        box-shadow: 0 0 0 0.2rem lighten($brand-gray, 10);
      }
    }

    &.btn-sm {
      padding: 5px 15px;
      font-size: 0.8rem;
    }

    &.btn-lg {
      padding: 10px 18px;
      font-size: 1rem;
    }
  }

  h1 {
    font-size: 1.5rem;
    font-weight: 600;
    letter-spacing: 3px;
  }
  h2 {
    font-size: 1.3rem;
    font-weight: 600;
    letter-spacing: 3px;
  }

  h3 {
    font-size: 1rem;
    font-weight: 600;
    letter-spacing: 2px;
  }

  h4 {
    font-size: 1rem;
    font-weight: 400;
    letter-spacing: 2px;
  }

  h5 {
    font-size: 0.9rem;
    font-weight: 400;
    letter-spacing: 2px;
  }

  h6 {
    font-size: 0.8rem;
    font-weight: 600;
    letter-spacing: 2px;
  }

  p {
    &.explic {
      color: $brand-gray;
      font-weight: 100;
      font-size: 1rem;
    }
  }

  .vox-payment-due-date {
    width: auto;

    input[type="text"],
    input[type="number"],
    input[type="email"],
    input[type="tel"],
    input[type="password"] {
      width: 137px;

      @media only screen and (max-width: 650px) {
        width: 100px;
      }
    }
  }

  select.vxm-error,
  input.vxm-error,
  textarea.vxm-error {
    border: 1px solid red !important;
  }

  input[type="text"],
  input[type="number"],
  input[type="email"],
  input[type="tel"],
  input[type="password"] {
    height: 40px;
    width: 100%;
    display: block;
    padding: 0px 15px 0px 15px;
    letter-spacing: 2px;
    font-size: 0.8rem;
    border-radius: 40px;
    border: 1px solid $brand-gray-light;
    margin-bottom: 8px;

    &input[type="password"] {
      border-right: none;
    }

    .active,
    &:active,
    &:focus {
      // TODO: aplicar apenas nos inputs das novas feats
      // border: 1px solid #05e7a5;
      box-shadow: none;
    }

    &.vxm-focused {
      border: 1px solid;
      border-image-source: linear-gradient(90deg, #05e7a5 0%, #01cecb 100%);
    }

    &.vxm-error {
      border: 1px solid red;
    }

    // &::placeholder {
    //   text-transform: uppercase;
    // }
    &:read-only,
    &.form-control-plaintext {
      border: none;
      font-weight: 600;
      margin-bottom: 8px;
      height: 30px;
    }
  }

  form {
    padding-top: 10px;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      text-align: left;
    }

    .form-check {
      margin-top: 20px;
      margin-bottom: 20px;
      text-align: left;
      text-transform: uppercase;
      letter-spacing: 1px;
      z-index: 1;
      .form-check-label {
        font-size: 0.9rem;
      }
    }

    .terms {
      margin-bottom: 5px;
    }

    hr {
      width: 100%;
      margin-top: 10px;
    }

    .PhoneInput {
      margin-bottom: 0px;
    }
    .form-group {
      z-index: 1;
    }
    .form-group.form-inline {
      input {
        margin-right: 10px;
      }
    }

    .btn {
      margin-top: 10px;
      margin-right: 8px;
    }
  }

  .vox-dash-spacing {
    margin-top: 50px;
  }
}

p {
  font-size: 1.1rem;
}

.vox-componentes-box {
  margin-top: 60px;
  margin-bottom: 60px;
  background-color: white;
  border-radius: 60px;
  padding: 60px 60px 30px;
}

.vox-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.8;
  background-color: #000;
}

table.table {
  border-top: 3px solid $brand-primary;
  text-align: left;

  thead {
    tr {
      th {
        text-transform: uppercase;
        font-weight: 600;
        letter-spacing: 1px;
        color: $brand-gray;
      }
    }
  }
  tr {
    th {
      font-weight: 600;
    }
  }
}

.modal {
  text-align: center;

  .modal-content {
    border-radius: 20px;
    box-shadow: 0 5px 5px #636363;

    .modal-header {
      padding-left: 30px;
    }

    .modal-body {
      padding: 2rem;
    }

    .modal-footer {
      justify-content: center;
    }
  }

  .btn {
    margin-bottom: 5px;
  }
}

.iti .iti__country-list {
  width: 242px;
}

.w-full {
  width: 100%;
}

.w-auto {
  width: auto;
}

.px-4 {
  padding-right: 1rem;
  padding-left: 1rem;
}

.custom-button {
  border: none;
  background-color: $brand-primary !important;
  border-radius: 100px !important;
  text-transform: uppercase;
  font-weight: 600 !important;

  &:hover {
    background-color: #21c97f !important;
  }

  a {
    color: $button-text-color;
  }
  border-color: {
    border-color: $brand-primary;
  }
}

.btn-primary {
  border-color: $brand-primary !important;
  color: #2e3031;
  padding: 8px 18px;
  font-size: 0.9rem;
  &:hover {
    background-color: #21c97f;
  }
}

.dark-button {
  background-color: #373a36 !important;
  border-color: #373a36 !important;
  color: #fff !important;
}

.custom-input {
  display: block;
  width: 100%;
  height: 40px;
  margin-bottom: 8px;
  padding: 0 15px;
  letter-spacing: 2px;
  font-size: 0.8rem;
  border-radius: 40px;
  border: 1px solid $brand-gray-light;
  text-transform: uppercase;
}

.textarea-limit {
  margin-top: 10px;
  font-size: 0.9rem;
  font-weight: 600;
  color: #7a7786;
}

.PhoneInput {
  margin-bottom: 8px;
  width: 100%;
  position: relative;

  &.phone-input-register {
    .PhoneInputCountry {
      background-color: transparent;
    }
  }

  .PhoneInputCountry {
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 19px 0 0 19px;
    outline: none;
    height: 40px;
    width: 70px;
    position: absolute;
    top: 0px;
    bottom: 0;
    right: 0;
    padding: 1px;
    right: auto;
    left: 0;

    &:focus {
      outline: none;
    }
    .PhoneInputCountryIcon {
      display: block;
      .PhoneInputCountryIconImg {
        vertical-align: top;
      }
    }
  }

  input[type="tel"].PhoneInputInput {
    height: 40px;
    width: 100%;
    display: block;
    padding: 0px 15px 0px 85px;
    letter-spacing: 2px;
    font-size: 0.8rem;
    border-radius: 40px;
    border: 1px solid $brand-gray-light;
    &:focus {
      outline: none !important;
    }
  }
}

.vox-in-phone-button {
  border-color: $brand-primary !important;
  &:hover {
    border-color: inherit;
    outline: 0;
  }
  a {
    color: $button-text-color;
    font-weight: 600;

    &:hover {
      color: inherit;
      text-decoration: inherit;
    }
  }
}

a {
  .btn-dark {
    &:hover {
      border-color: #21c97f !important;
    }
  }
  button {
    &:focus {
      border-color: inherit;
    }
  }
}

.w-3-4 {
  width: 75% !important;
}

.overflow-x-auto {
  overflow-x: auto;
}

.cursor-pointer {
  cursor: pointer;
}

.font-semibold {
  font-weight: 600;
}
