@import "./shared.scss";

.header-menu {
  nav {
    height: 120px;
    transition: height 0.4s;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
    @media only screen and (max-width: 992px) {
      height: auto;
      a {
        padding: 0.5rem 1rem;
        display: block;
      }
    }
    @media only screen and (min-width: 992px) {
      a {
        padding: 0.5rem 1rem;
        padding-left: 0.8rem !important;
        padding-right: 0.8rem !important;
      }
    }
    @media only screen and (min-width: 1200px) {
      a {
        padding: 0.5rem 1rem;
        padding-left: 1rem !important;
        padding-right: 1rem !important;
      }
    }
    &.sticky {
      height: 60px;

      @media only screen and (max-width: 992px) {
        height: auto;
      }
    }
    div {
      text-transform: uppercase;
      @media only screen and (min-width: 993px) {
        display: flex;
      }
      .logo {
        margin-right: 1rem;
      }
      a {
        color: inherit;
        text-decoration: none;
        cursor: pointer;
        &:hover {
          color: $brand-primary;
        }
        @media only screen and (min-width: 993px) {
          padding-left: 1rem;
          div {
            padding-left: 1rem;
          }
        }
      }
    }
  }
}

.logo img {
  width: 190px;
}

.language-selector {
  list-style: none;
}

@media only screen and (max-width: 991px) {
  .language-selector {
    float: right;
  }
}

.navbar-toggler-icon {
  &:before {
    content: none !important;
  }
}

#custom-selector {
  background-color: initial !important;
  border: none;
  font-size: 0.7rem;
  color: #828282;
  font-weight: 500 !important;
  &:hover {
    color: $brand-primary;
  }
}

.btn-dark {
  border-radius: 2.25rem !important;
}

.header-user-info {
  display: flex;
  span {
    margin-right: 9px;
  }
  img {
    width: 25px;
    height: 25px;
  }
}

.current-path {
  font-weight: 900;
}
