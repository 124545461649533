@import "./shared.scss";

$gradient-02: linear-gradient(90deg, #05e7a5 0%, #01cecb 100%);

#vox-assinante-my-warnings {
  .content-form-title {
    margin: 50px 0 56px 0;
  }
}

#warning-container {
  text-align: center;

  .col-flex {
    display: flex;
    align-items: center;

    span.title-list {
      margin: 4px 10px 4px 30px;
    }

    &.justify-center {
      justify-content: center;
    }

    &.justify-left {
      justify-content: flex-start;
    }

    &.justify-right {
      justify-content: flex-end;
    }
  }

  .infinite-scroller {
    overflow-x: hidden !important;
  }

  .filled-button {
    background-color: $gray-100;
    border-radius: 12px;
    width: 44px;
    height: 44px;
    padding: 12px;
  }

  .outlined-button {
    background-color: transparent;
    border: 1px solid $gray-500 !important;
  }

  .filled-button,
  .outlined-button {
    color: $gray-500;

    &:active,
    &:focus {
      box-shadow: none;
    }
  }

  .icon-text-btn {
    font-size: 11px;
    padding: 12px 8px;
    border-radius: 1000px;
    min-width: 80px;
    .title {
      margin-left: 6px;
      text-transform: capitalize;
      font-size: 9px;
    }
  }

  .icon-btn.enabled {
    color: #fff;
    background: $gradient-02;
  }

  #infinite-scroll-wrapper {
    overflow-x: hidden;
    overflow-y: auto;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .warnings-header {
    color: $gray-400;
    font-weight: 700;
    font-size: 0.75rem;
    margin: 0 22px;
  }

  .warnings-body {
    font-size: 0.875rem;
    font-weight: 300;

    .row {
      margin-left: 0px !important;
      margin-right: 0px !important;
    }

    .warning-item {
      background-color: #fff;
      margin-top: 8px;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08);
      border-radius: 16px;
      padding: 22px;

      .text-ellipsis {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .bold {
        font-weight: 700;
      }

      .checked {
        .react-switch-bg {
          background: $gradient-02 !important;
        }
      }
    }
  }
}

.warning-card {
  font-size: 0.75rem;

  .title {
    font-size: 1.25rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  button {
    font-size: 0.75rem;
    text-transform: capitalize;
  }
}

@media (max-width: 766px) {
  .content-form-title {
    font-size: 1.25rem !important;
    margin: 20px 0 !important;
  }
}

.buton-take-remin-mobile {
  background: chocolate;
}
