@import "./shared.scss";

.info-label-wrapper {
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 0.5em 0 1.5em 0em;

  &.info-label-wrapper-register {
    justify-content: center;

    margin-bottom: 30px;

    /* These are technically the same, but use both */
    overflow-wrap: break-word;
    word-wrap: break-word;

    -ms-word-break: break-all;
    /* This is the dangerous one in WebKit, as it breaks things wherever */
    word-break: break-all;
    /* Instead use this non-standard one: */
    word-break: break-word;

    .btn-label-info {
      margin-left: 10px;
    }
  }

  .info-label {
    text-transform: lowercase;
    display: inline;
    margin-left: 1em;
  }
  .btn-label-info {
    color: $brand-primary;
    background-color: transparent;
    padding: 8px;
    margin: 0;
  }
}

.vox-steps-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.vox-steps-wrapper-admin {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.vox-steps-wrapper > .vox-step {
  width: 30%;
  margin-left: 8px;
}

.vox-steps-wrapper > .steps-title {
  text-align: center;
  color: #373a36;
  margin: 20px 0;
}

.vox-steps-wrapper-admin > .vox-step {
  display: flex;
  width: 30%;
  justify-content: center;
}

.vox-steps-wrapper-admin > .steps-title {
  color: $brand-primary;
  margin-bottom: 18px;
}

.steps-title {
  color: $brand-primary;
  margin-bottom: 5px;
}

.container-steps {
  flex-direction: column;
}

.vox-steps-admin {
  display: flex;
  width: 30%;
  justify-content: center;
}

.buttons-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.buttons-wrapper-new-user-contact {
  display: flex;
  flex-direction: row;
}

.logo-wrapper > a > .logo {
  max-width: 110px;

  &.logo-register {
    max-width: 220px;
  }
}

@media (max-width: 576px) {
  .vox-steps-wrapper > .vox-step {
    margin-left: -8px;
  }
  .logo-wrapper > a > .logo {
    text-align: center;
    max-width: 40%;

    &.logo-register {
      max-width: 75%;
    }
  }
  .buttons-wrapper {
    flex-direction: column;
    text-align: center;
    padding: 0 12.5%;
  }
  .rc-steps-label-vertical .rc-steps-item {
    width: 80px;
  }
  .vox-steps-admin {
    display: flex;
    width: 30%;
    justify-content: center;
  }
}

.password-row,
.password-row-new-user {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;

  input.input-password {
    width: 85%;
    border-radius: 40px 0 0 40px;
    border-right: none !important;
    -webkit-appearance: none;

    &.vxm-error {
      border-right: none;
    }
  }

  svg.input-password {
    width: 15%;
    height: 40px;
    padding: 10px 12px 10px 6px;
    border: 1px solid $brand-gray-light;
    border-radius: 0 40px 40px 0;
    border-left: none;

    &.vxm-error {
      border: 1px solid red;
      border-left: none;
    }
  }
}

.table-action-wrapper {
  display: flex;
  justify-content: flex-end;
}

.table-action-button {
  display: flex !important;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: none !important;
  & > .table-action-icon {
    color: $button-text-color;
    height: 0.9em;
    margin-right: 8px;
  }
  & > .table-action-label {
    font-size: 0.8em;
  }
}

.wrapper-info-position {
  display: flex;
  width: auto !important;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  span {
    padding-top: 8px;
  }

  @media (max-width: 650px) {
    & {
      display: none !important;
    }
  }
}
