@import "./shared.scss";

$side-menu-primary: #05e7a5;
$side-menu-secondary: #01cecb;

.side-menu {
  overflow: auto;
  max-width: 22%;
  min-width: 15%;
  height: 100%;
  font-weight: 700;
  background: linear-gradient(
    180deg,
    $side-menu-primary 0%,
    $side-menu-secondary 100%
  );
  display: flex;
  align-items: center;
  flex-direction: column;

  .side-top-menu {
    display: flex;
    padding-top: 30px;
    align-items: center;
    flex-direction: column;
    a {
      width: 50%;
      img {
        width: 100%;
      }
    }
    .user-container {
      display: flex;
      align-items: center;
      img {
        border-radius: 50%;
        max-width: 32px;
        margin: 0 8px;
        border: 1px solid #fff;
        padding: 3px 5px;
      }
      .user-detail {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        h2 {
          font-size: 1rem;
          font-weight: 700;
          letter-spacing: 2px;
          margin: 0;
          color: #ffffff;
        }
        h3 {
          margin-top: -5px;
          font-size: 1rem;
          font-weight: 100;
          letter-spacing: 2px;
        }
      }
    }
  }

  .accordion {
    width: 100%;
    height: 100%;
    padding: 16px 32px;

    a,
    button {
      padding: 0px;
      color: #fff;
      text-decoration: none;
      text-transform: none;
    }

    .navigation-link {
      .menu-item {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 66px;
        padding: 8px;
      }
    }

    .menu-item {
      height: 66px;
      padding: 0 8px;
      text-align: center;
      margin: 8px 0px;

      &.current-path-side,
      &:hover {
        background: rgba(255, 255, 255, 0.25);
        border-radius: 12px;
      }
    }

    .menu-item ~ .submenu {
      margin-bottom: 0px;
    }

    .submenu {
      font-size: 12px;

      .navigation-link {
        .menu-item {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 35px;
          padding: 0 8px;
        }
      }

      .menu-item {
        padding: 8px;
        margin: 0px;

        &.current-path-side,
        &:hover {
          background: none;
          text-decoration: underline;
        }
      }
    }
  }

  .side-exit {
    text-decoration: none;
    bottom: 0;
    height: 40px;
    color: #fff;
    line-height: 40px;
    cursor: pointer;
    width: 100%;
    a {
      text-decoration: none;
      display: block;
      width: 100%;
      text-align: center;
      color: #fff;
      &:hover {
        color: #fff;
      }
    }
  }
}

.header-menu-mobile {
  background: linear-gradient(
    180deg,
    $side-menu-primary 0%,
    $side-menu-secondary 100%
  );

  .nav-link {
    color: #fff !important;
    font-weight: 700;
  }

  .dropdown-menu {
    font-size: 0.75rem;
    background: none;
    border: none;
    padding-left: 5%;
  }

  #logo-mobile {
    img {
      margin: 0;
    }
  }
}

.web-container {
  display: flex;
  flex-direction: row;
  height: 100%;

  .side-menu {
    height: 100vh;
    width: 100%;
    position: relative;
  }
  .content-container {
    width: 100%;
    overflow-y: scroll;
    position: relative;
    .internas {
      width: 95%;
    }
  }
}

.mobile-container {
  display: flex;
  flex-direction: column;
  height: 100%;

  .header-menu-mobile {
    position: fixed;
    width: 100%;
    z-index: 999;
  }
  .content-container {
    margin-top: 70px;
    max-width: 100%;
    overflow-x: hidden;
    position: relative;
  }
}

#vox-assinante-quem-usa {
  form {
    width: 100%;
  }
  h2 {
    margin-top: 50px;
  }
}
