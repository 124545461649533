@import "./shared.scss";

/* área do assinante */
.web-container {
  .content-container {
    .internas.my-account-contacts {
      width: 95%;
    }
  }
}

.internas.history-container {
  padding: 100px 0 0 !important;
}

.modal-body {
  textarea,
  input {
    margin-bottom: 8px;
  }
}

div.assisted-selector {
  position: absolute;
  top: 50px;
  right: 50px;

  @media screen and (max-width: 767px) {
    position: relative;
    top: 40px;
    margin-bottom: 35px;
    left: 15px;
  }

  #custom-assisted-selector {
    background-color: #ffffff;
    border: 1px solid #f1f0f5 !important;
    color: #5f5c6b;
    font-size: 1rem;
    text-transform: none;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;

    &:focus {
      box-shadow: none;
    }

    &::after {
      display: none;
    }
  }

  .assisted-selector-menu {
    border-radius: 16px;
    margin-top: 8px;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08);
    border: 0;

    .dropdown-item:hover {
      background-color: #f1f0f5;
    }
  }

  img {
    margin-right: 15px;
  }

  img:last-of-type {
    margin: 0 0 0 15px;
  }
}

.vox-assinante-content {
  padding: 72px 0 60px 60px !important;
  overflow: auto;
  overflow-x: hidden;
  height: 100%;

  .text-breadcrumb,
  .text-breadcrumb > a,
  .text-breadcrumb > a:hover {
    color: $text-color;
  }

  h2.content-form-title {
    font-size: 2rem;
    text-transform: none;
    font-weight: 700;
    margin: 50px 0 25px;
  }

  .container-select-contact {
    padding: 0 0 35px;

    button.select-contact {
      display: flex;
      align-items: center;
      background-color: transparent;
      padding: 6px 14px 8px;
      border-radius: 20px;
      border: 2px solid #01cecb;
      font-size: 16px;
      font-weight: 700;
      color: #8c8a97;
      outline: none;
    }

    img.arrow-right {
      margin: 2px 0 0 12px;
      height: 8px;
      transform: rotate(270deg);
    }
  }

  form.update-payment-form {
    .vox-cartoes {
      margin-left: -70px !important;

      &.cc-br {
        margin-left: 0 !important;
        width: 100%;
      }
    }

    .vox-cards-container {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: flex-start;
      margin: 2% 0;

      .vox-card {
        width: 44px;
        margin: 1%;
      }
    }
  }

  form.vox-form {
    div.form-group.row {
      margin-left: 0;
      margin-right: 0;

      .PhoneInput,
      .password-row {
        width: 75%;
      }
    }
    & > .wrapper-button {
      padding-bottom: 0.5em;
    }
  }

  @media only screen and (max-width: 992px) {
    padding: 20px 20px 0 20px !important;

    .iti {
      float: left;
    }
  }

  form.vox-login-form {
    padding-top: 0px;

    &.my-contacts {
      width: 100% !important;
    }

    .table td,
    .table th {
      padding: 0.75rem 0.75rem 0.3rem;

      &.remove-contact {
        padding-top: 1.2rem;
        cursor: pointer;

        @media only screen and (max-width: 650px) {
          padding-top: 0.75rem;
          padding-bottom: 0.75rem;
        }
      }
      @media only screen and (max-width: 650px) {
        &.empty-column {
          display: none;
        }
      }
    }

    @media only screen and (max-width: 650px) {
      .row.actions {
        justify-content: center;
        margin-bottom: 15px;
      }
    }
  }
  .contact-action-button-wrapper {
    margin-top: 1.5em;
    margin-bottom: 1em;
    .action-button > .table-action-icon {
      margin-right: 0.5em;
    }
  }
}

.vox-history-content {
  overflow: auto;
  overflow-x: hidden;

  @media only screen and (min-width: 768px) {
    padding: 50px 0 0 60px !important;
    justify-content: center;
    margin-bottom: 15px;
  }
}

.history-action-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 1em;
  @media only screen and (max-width: 768px) {
    margin-bottom: 2em;
    & > a {
      width: 100%;
      text-align: center;
      .action-button {
        width: 60%;
      }
    }
  }
}

.iti {
  .iti__selected-flag {
    border-radius: 40px 0 0 40px;
  }
}

table.vox-historico {
  text-align: center;
}
div.vox-historico-container {
  width: calc(100% - 50px);
  overflow: auto;
}

@media only screen and (max-width: 992px) {
  div.vox-historico-container,
  table.vox-contatos {
    width: 100%;
  }
  table.vox-historico {
    width: 100%;

    th,
    td {
      padding: 0 5px;
      font-weight: 100;
    }
  }
}

@media only screen and (min-width: 650px) {
  .vox-th-mobile {
    display: none;
  }
}
@media only screen and (max-width: 650px) {
  .vox-assinante-content {
    text-align: center;

    h2,
    p.explic {
      text-align: left;
    }
  }
}

@media only screen and (max-width: 400px) {
  div.vox-historico-container,
  table.vox-contatos {
    width: calc(100% - 25px);
  }
}
table.vox-contatos {
  th,
  td {
    width: 33%;
    padding-bottom: 0.25rem;
  }

  @media only screen and (max-width: 650px) {
    .vox-th-mobile {
      text-align: center;
    }

    thead {
      display: none;
    }
    th,
    td {
      display: block;
      width: 100%;
      padding-bottom: 0.5rem;

      .iti {
        width: 75%;
        margin-bottom: 8px;
        height: 40px;
        left: 50%;
        transform: translateX(-50%);

        input {
          width: 100% !important;
        }
      }
    }
  }
}

.account-uses {
  padding: 100px 0 0 60px;
  width: 75%;
  @media screen and (max-width: 767px) {
    padding: 20px 20px 0 20px;
  }
  h2 {
    font-size: 1.3rem;
    font-weight: 600;
    letter-spacing: 3px;
    text-transform: uppercase;
  }

  p {
    color: #373a36;
    font-weight: 100;
    font-size: 1rem;
  }
}

.invoice-wrapper {
  border: 1px solid $text-color;
  border-radius: 1.4em;
  padding: 0.5em 1em;
  width: 70%;
  margin-bottom: 1em;

  .title-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    .title-name {
      font-size: 1.1em;
      margin: 0;
    }
  }

  .invoice-body {
    margin-top: 1em;
    .invoice-row-detail {
      display: flex;
      align-items: center;
      justify-content: space-between;
      p {
        font-size: 0.9rem;
        color: $text-color;
      }
    }
  }
}
