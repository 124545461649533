@import "./shared.scss";

#vox-assinante-remind-me {
  overflow: hidden;

  @media screen and (max-width: 768px) {
    margin-bottom: 30px;
    padding-bottom: 30px;
  }
}

.remind-title {
  padding: 32px 0;
  color: #4e4b59;
}

.new-remind-form {
  color: #7a7786;
  h4 {
    padding-bottom: 16px;
    color: #7a7786;
  }

  select,
  input {
    margin: 0;
    height: 40px;
    width: 100%;
    display: block;
    padding: 0px 15px 0px 15px;
    letter-spacing: 2px;
    font-size: 0.8rem;
    border-radius: 40px;
    border: 1px solid $brand-gray-light;
    background-color: #ffffff;
  }

  .react-datepicker-wrapper {
    width: 100%;
  }

  .date-picker-container {
    overflow: hidden;

    .date-picker {
      .active,
      &:active,
      &:focus {
        border: 1px solid #05e7a5;
        box-shadow: none;
        outline: none;
      }
    }

    input {
      background: url("../img/calendar.svg") no-repeat transparent;
      background-position: 95%;
    }
  }
}

.modal-select-contact {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 30px 20px;

  button.button-contact {
    background: none transparent !important;
    color: #7a7786;
    width: 100%;
    font-weight: 600;
    text-align: left;
    margin: 5px 0;
    padding: 6px 12px;
    border: 1.5px solid #7a7786 !important;
    border-radius: 18px;
    outline: none;

    &:hover {
      border: 1.5px solid #4b494e !important;
    }
    &:focus {
      border: 1.5px solid #7a7786 !important;
    }
    &:active {
      border: 1.5px solid #7a7786 !important;
    }
  }
}

.select-date {
  display: flex;
  align-items: center;
  justify-content: left;
  padding-bottom: 32px;

  button {
    border: 0.5px solid #5f5c6b;
    border-radius: 8px;
    background-color: transparent;
    display: flex;
    padding: 16px;
  }
  .previous-day img {
    transform: rotateZ(90deg);
  }
  .next-day img {
    transform: rotateZ(-90deg);
  }
  p {
    margin: 0 16px;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 32px;
    color: #7a7786;
  }
}

.hour {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 8px;
  margin: 25px 0;
  border: 1px solid #c6c5ce;
  box-sizing: border-box;
  border-radius: 8px;
  width: 102px;

  span {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: #7a7786;
  }
}

.containerSwitch {
  display: inline-block;
  vertical-align: middle;
  margin: 6px 8px 0 8px;
}

.reminder {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 16px 0;
  padding: 22px 0;
  background: #ffffff;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08);
  border-radius: 16px;

  .medicine-icon {
    display: flex;
    align-items: center;
    img {
      background: #f1f0f5;
      border-radius: 12px;
      padding: 16px;
    }
    h3 {
      margin: 0 24px;
    }
  }

  .content {
    span {
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 24px;
      display: flex;
      align-items: center;
      color: #acabb7;
    }
  }

  .buttons {
    button {
      margin: 0 8px;

      &.btn-primary {
        color: #fff !important;
        background-image: linear-gradient(
          to left,
          lighten($brand-primary, 10),
          lighten($brand-secondary, 10)
        );
      }
    }
  }

  @media screen and (max-width: 992px) {
    display: block;

    .medicine-icon {
      h3 {
        margin: 0 0 0 10px;
        text-align: left;
      }
    }

    .content,
    .buttons {
      width: fit-content;
      display: inline-block;
    }
  }
}

.button-remind {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: none transparent;
  font-size: 12px;
  padding: 12px 16px;
  border-radius: 1000px;
  color: #8c8a97;
  border: 1px solid #8c8a97 !important;
  .title {
    margin-left: 6px;
    text-transform: capitalize;
  }
}

.container-buttons-remind {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  justify-content: flex-end;
  padding: 0 22px;
}

.container-header-remind {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  justify-content: flex-end;
}
