@import "./shared.scss";

.breadcrumb-container {
  a {
    color: #7a7786;
    font-size: 0.875rem;
    font-weight: 300;
    font-family: cera;

    &:hover {
      color: #514f58;
    }
  }
}
