$text-color: #999;
$button-text-color: #2e3031;
$brand-primary: #27dc8c;
$brand-secondary: #23dbd0;
$brand-gray: #373a36;
$brand-gray-light: #ccc;
$header-height: 120px;

$gray-100: #f1f0f5;
$gray-200: #e1e0e7;
$gray-300: #c6c5ce;
$gray-400: #acabb7;
$gray-500: #8c8a97;
$gray-600: #7a7786;
$gray-700: #5f5c6b;
