@import "./shared.scss";

.vox-steps-wrapper {
  text-align: left;
}
.rc-steps {
  justify-content: center;
}
.rc-steps-item-icon > .rc-steps-icon {
  color: $brand-primary;
}

.rc-steps-item-title {
  color: $text-color;
}

.rc-steps-item-subtitle {
  color: $text-color;
}

.rc-steps-item-description {
  color: $text-color;
}

.rc-steps-item-process .rc-steps-item-icon {
  border-color: $brand-primary;
}

.rc-steps-item-process .rc-steps-item-icon > .rc-steps-icon {
  color: #fff;
}

.rc-steps-item-process .rc-steps-item-icon > .rc-steps-icon .rc-steps-icon-dot {
  background: $brand-primary;
}

.rc-steps-item-process .rc-steps-item-icon {
  background: $brand-primary;
}

.rc-steps-item-finish .rc-steps-item-icon {
  border-color: $brand-primary;
}

.rc-steps-item-finish .rc-steps-item-icon > .rc-steps-icon {
  color: $brand-primary;
}
.rc-steps-item-finish .rc-steps-item-icon > .rc-steps-icon .rc-steps-icon-dot {
  background: $brand-primary;
}

.rc-steps-item-finish .rc-steps-item-title:after {
  background-color: $brand-primary;
}

.rc-steps-item-finish .rc-steps-item-tail:after {
  background-color: $brand-primary;
}

.rc-steps-item-custom.rc-steps-item-process
  .rc-steps-item-icon
  > .rc-steps-icon {
  color: $brand-primary;
}

.rc-steps-navigation .rc-steps-item-active .rc-steps-item-container {
  border-bottom: 3px solid $brand-primary;
}
