h3 {
  font-weight: 600;
  letter-spacing: 1.5px;
  font-size: 1rem;
}

.vox-pagamento {
  .price {
    color: #23dbd0;
    display: block;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 2rem;
  }
  .vox-cartoes {
    &.cc-br {
      width: 100%;
    }
  }

  .vox-cards-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: 2% 0;

    .vox-card {
      width: 44px;
      margin: 1%;
    }
  }
}
