.register-container-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.choose-user {
  &-title {
    margin: 40px 0 30px;
    font-size: 1.3rem;
  }

  &-buttons-container {
    display: flex;
    justify-content: center;
    align-items: center;

    @media only screen and (max-width: 991px) {
      flex-direction: column;
    }
  }

  &-button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px 15px;
    background-color: #f6f6f6;
    margin-right: 20px;
    border-radius: 30px;

    @media only screen and (max-width: 991px) {
      margin-right: 0;
      margin-bottom: 20px;
    }

    &:last-of-type {
      margin-right: 0px;
    }

    h4 {
      font-weight: 600;
      font-size: 1.4rem;
      text-transform: none;
      letter-spacing: 0;

      &::first-letter {
        text-transform: capitalize;
      }
    }
  }
}

h3.choose-user-title {
  font-size: 1.3rem;
  letter-spacing: 0;
}

button.button-gradient-register {
  background: linear-gradient(to left, #27dc8c, #23dbd0);
  padding: 7px 30px !important;
  color: #fff !important;
  font-size: 0.8rem;
  -webkit-transition: opacity 0.15s ease-in-out;
  -moz-transition: opacity 0.15s ease-in-out;
  -o-transition: opacity 0.15s ease-in-out;
  transition: opacity 0.15s ease-in-out;

  &:hover {
    opacity: 0.8;
  }
}

button.button-light-register {
  padding: 7px 30px !important;
  color: #ccc !important;
  font-size: 0.8rem;
  background-color: #fff;

  &:not(:focus) {
    border-color: #ccc !important;
  }
}

h3.register-form-title {
  text-transform: lowercase;
  font-weight: 600;
  font-size: 1.5rem;
  letter-spacing: 0;
  margin: 50px 0 5px;

  &::first-letter {
    text-transform: capitalize;
  }

  &.assisted-form-title {
    margin-bottom: 45px;
    text-transform: initial;
  }

  &-lower {
    text-transform: initial;
  }
}

.form-register {
  justify-content: space-evenly;
}

div.password-input-register {
  padding: 0;

  svg.input-password {
    padding: 12px 12px 12px 6px;
    border-color: #ccc;
  }
}

div.input-register-container {
  padding: 0;
  margin-bottom: 20px;
  position: relative;

  @media only screen and (max-width: 992px) {
    margin-bottom: 0;
  }

  &.input-edit-email {
    @media only screen and (min-width: 992px) {
      max-width: 89%;
    }
  }

  &.vox-payment-due-date {
    .input-register {
      width: 100%;
    }
  }
}

input[type="text"].input-register,
input[type="number"].input-register,
input[type="email"].input-register,
input[type="tel"].input-register,
input[type="password"].input-register,
.input-register > input[type="tel"].PhoneInputInput {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-color: #ccc;
  background-color: transparent;
}

.input-register.vxm-error > input[type="tel"].PhoneInputInput {
  border-color: red;
}

.buttons-register-container {
  justify-content: center;
  margin: 40px 0;

  @media only screen and (max-width: 992px) {
    flex-direction: column;
    margin: 0;
  }

  &.buttons-checkemail-container {
    @media only screen and (min-width: 993px) {
      margin: 10px 0 0;
    }
  }
}

.input-register {
  & + label {
    position: absolute;
    top: -8px;
    left: 16px;
    font-size: 0.7rem;
    background-color: #fff;
    color: #ccc;
  }
}

table.contact-table-register {
  border-top: none;

  div.input-register-container {
    margin: 0;
  }

  tbody tr:nth-of-type(odd) .input-register-container label {
    background-color: #f2f2f2;
  }
}

.login-body
  .internas.login
  main
  .vox-login-form-container.contatos
  table.contact-table-register {
  div.vxm-error {
    margin-top: 0;
  }

  td .PhoneInput {
    margin-bottom: 0;
  }

  input {
    margin-bottom: 8px;
  }
}

.internas button.btn.action-contact-button-register {
  padding: 10px 40px;
  text-transform: lowercase;
  font-size: 1.1rem;
}

.payment-wrapper-register {
  display: flex;
  margin: 60px 30px 0px;
  justify-content: space-around;

  @media only screen and (max-width: 992px) {
    flex-direction: column;
    margin: 0;
  }

  .payment-info-container-register {
    text-align: center;

    .vox-cards-container {
      justify-content: center;
    }

    .register-form-title {
      margin-top: 0;
      text-transform: uppercase;
      font-weight: 900;
    }

    .register-form-subtitle {
      margin-top: 20px;
      font-style: italic;
      letter-spacing: 0;
      font-size: 1rem;

      &:nth-of-type(2) {
        margin-top: 50px;
      }
    }
  }

  .payment-form-container-register {
    background-color: #f3f3f3;
    padding: 50px 50px 20px;
    border-radius: 20px;

    @media only screen and (max-width: 992px) {
      margin: 30px 0;
    }

    @media only screen and (max-width: 375px) {
      padding-right: 30px;
      padding-left: 30px;
    }

    .form-group-register {
      justify-content: space-between;
    }

    .form-group {
      margin-bottom: 0;
    }
  }
}

.vox-pagamento {
  .buttons-register-container {
    margin-bottom: 20px;

    button {
      @media only screen and (max-width: 992px) {
        margin-right: 0;
      }
    }
  }
}

.register-box .buttons-register-container {
  button {
    @media only screen and (max-width: 992px) {
      margin-right: 0;
    }
  }
}

.payment-form-container-register {
  .input-register-container {
    label {
      background-color: #f3f3f3;
    }
  }
}

.register-payment-form-price {
  font-size: 1.5rem;
  font-style: italic;
  font-weight: 600;
}

.contact-action-icon-register {
  color: #27dc8c;
}

.register-box-choose-user {
  .buttons-register-container {
    margin-right: 0;
    margin-left: 0;
  }
}

.register-box .rc-steps-label-vertical {
  .rc-steps-item {
    @media only screen and (max-width: 355px) {
      width: auto;
    }
  }

  .rc-steps-item-content {
    @media only screen and (max-width: 355px) {
      width: 80px;
    }
  }
}
