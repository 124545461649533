@import "./shared.scss";

.internas {
  #vox-assinante-quem-usa,
  #vox-assinante-remind-me {
    .internal-form {
      .react-datepicker-wrapper {
        width: 100%;
      }

      .date-picker-container {
        overflow: hidden;

        .date-picker {
          .active,
          &:active,
          &:focus {
            border: 1px solid #05e7a5;
            box-shadow: none;
            outline: none;
          }
        }

        input {
          background: url("../img/calendar.svg") no-repeat transparent;
          background-position: 95%;
        }
      }

      &-subtitle {
        text-transform: none !important;
        color: #7a7786;
        font-size: 1.2rem;
        line-height: 25px;
        margin-top: 30px;
      }

      &-label {
        color: #7a7786;
        font-weight: 600;
      }

      &-row {
        margin-top: 20px;

        @media screen and (max-width: 767px) {
          margin-top: 0;
        }

        &:first-of-type {
          margin-top: 40px;
        }

        &:last-of-type {
          margin-top: 0;
        }

        .location-input {
          .css-53aytk-indicatorContainer > svg,
          .css-1m7qm53-indicatorContainer > svg {
            width: 18px;
            height: 15px;
          }

          .css-1szdr1p .css-ngio4f > div > input {
            margin: 0.8rem;
          }
        }
      }

      &-input,
      &-select {
        padding: 0 25px;
        border-radius: 40px;
        margin-bottom: 10px;
        height: 40px;
        border: 1px solid #ccc;

        &:focus {
          border: 1px solid $brand-primary;
          box-shadow: none;
        }
      }

      &-select {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        padding: 0 25px;
        height: 40px;
        background: url("../img/arrow.svg") 93% / 2.5% no-repeat;
        background-size: 8px;

        &::-ms-expand {
          display: none; /* Remove default arrow in Internet Explorer 10 and 11 */
        }

        &:focus {
          background-image: url("../img/arrow-green.svg");
        }
      }

      &-textarea {
        height: 192px;
        border-radius: 16px;
        border: 1px solid hsl(0, 0%, 80%);
        padding: 1.5rem;
        resize: none;
        margin-bottom: 10px;

        &:focus {
          outline: none;
          border: 1px solid $brand-primary;
        }
      }

      &-button {
        width: 100%;
        height: 70px;
        border-radius: 16px !important;
        font-size: 1.2rem;
      }
    }
    .internal-icon-clear {
      background: transparent;
      border: none;
      margin-left: 4px;
    }
  }

  #vox-assinante-remind-me {
    .internal-form {
      &-subtitle {
        margin-top: 0px;
        margin-bottom: 30px;

        &:last-of-type {
          margin-top: 40px;
        }
      }
    }
  }
}
