@import "./shared.scss";

.event-tracking {
  width: 100%;
  display: flex;
  flex-flow: column;
  height: 100%;

  header {
    @media only screen and (max-width: 992px) {
      text-align: center;
    }

    .vxm-gradient {
      height: 15px;
      width: 100%;
      background-image: linear-gradient(
        to left,
        lighten($brand-primary, 10),
        lighten($brand-secondary, 10)
      );
    }

    .row {
      margin-right: 0px;
      margin-left: 0px;

      .logo-container {
        @media only screen and (min-width: 992px) {
          padding: 30px 0px 25px 70px;
        }

        .logo {
          max-width: 250px;

          @media only screen and (max-width: 992px) {
            margin-top: 15px;
            margin-bottom: 10px;
            width: 50px;
          }
        }
      }

      .tracking-info {
        @media only screen and (min-width: 993px) {
          text-align: right;
          padding-top: 30px;
          padding-right: 40px;
          font-weight: 600;
        }

        p {
          margin-bottom: 0.3rem;

          &.title {
            color: $brand-secondary;
            font-size: 1.2rem;
          }

          @media only screen and (max-width: 992px) {
            &.title {
              font-size: 1rem;
              margin-top: 5px;
            }

            &.subtitle {
              font-size: 0.7rem;
              margin-bottom: 0rem;
            }
          }
        }
      }
    }

    hr {
      margin-top: 0px;
      margin-bottom: 0px;
    }
  }

  .event-info {
    position: relative;

    .hidden {
      display: none;

      @media only screen and (min-width: 992px) {
        display: block;
      }
    }

    .row {
      margin-left: 0px;
      margin-right: 0px;

      .request-info,
      .current-tracking-info {
        padding: 15px 0px;

        @media only screen and (min-width: 993px) {
          width: 40%;
        }

        @media only screen and (max-width: 992px) {
          border-bottom: 1px solid #ddd;
          width: 100%;
        }

        .col-1 {
          @media only screen and (min-width: 992px) {
            margin-top: 2px;
            text-align: right;
            padding-right: 0px;
          }

          svg {
            margin-top: 5px;
          }
        }

        .col {
          padding-left: 20px;

          p {
            margin-bottom: 0.2rem;
            font-size: 1rem;
            line-height: 1.2;

            &:nth-child(1) {
              font-size: 1.2rem;

              @media only screen and (max-width: 992px) {
                font-size: 1rem;
              }
            }

            &.address {
              line-height: 0.9;
              small {
                text-transform: capitalize;
                font-weight: 400;
                color: #555;
              }
            }
          }
        }
      }

      .request-info {
        @media only screen and (min-width: 992px) {
          padding-left: 25px;
        }

        .col-1 {
          svg {
            color: #db3124;
          }
        }

        .col {
          p {
            &:nth-child(1) {
              span {
                &:nth-child(1) {
                  font-weight: 600;
                  color: $brand-secondary;
                }

                &:nth-child(2) {
                  margin-right: 10px;
                  margin-left: 10px;
                }

                &:nth-child(2),
                &:nth-child(3) {
                  color: #515151;
                  font-weight: 100;
                }
              }
            }

            &:nth-child(2) {
              span b {
                font-weight: 600;
              }

              small {
                display: block;
                font-weight: 100;
                text-transform: uppercase;
                font-size: 70%;

                @media only screen and (min-width: 992px) {
                  display: inline;
                  margin-left: 10px;
                  margin-top: 0px;
                  font-size: 75%;
                }
              }
            }
          }
        }
      }

      .current-tracking-info {
        .col-1 {
          svg {
            color: $brand-primary;
          }
        }

        &.running {
          .col-1 {
            svg {
              color: #a32bd1;
            }
          }
        }

        .col {
          p {
            &:nth-child(1) {
              font-weight: 600;
            }
          }

          .event-time {
            line-height: 1.2;

            .spinner {
              margin-top: -12px;
              fill: $brand-secondary !important;

              svg {
                margin-top: -6px;
              }
            }
          }
        }
      }

      .awaiting-location {
        span {
          vertical-align: middle;
          font-weight: 600;
          font-size: 1.2rem;
        }

        .spinner {
          fill: $brand-secondary !important;
        }

        @media only screen and (max-width: 992px) {
          width: 100%;
          text-align: center;

          span {
            font-size: 1rem;
          }
        }
      }
    }

    .expand-retract {
      margin-top: 0.7rem;
      margin-bottom: 0.7rem;
      color: $brand-primary;
    }
  }

  .event-tracking-map {
    height: 90vh;
    flex: 1 1 auto;

    @media only screen and (max-width: 380px) {
      height: 85vh;
    }

    .position-info {
      span.assisted-name {
        display: block;
        text-transform: uppercase;
        font-weight: 600;
      }

      .address {
        font-size: 400;
        text-transform: capitalize;
      }
    }
  }

  .event-status {
    font-size: 1rem;
    font-weight: 600;
    color: #414141;

    @media only screen and (min-width: 993px) {
      padding: 8px 15px;
      position: absolute;
      right: 25px;
      top: 18%;
    }

    @media only screen and (max-width: 992px) {
      margin: 0 auto;
      left: 0;
      right: 0;
      position: relative;
      display: block;
      width: fit-content;
      text-align: center !important;
    }

    p {
      margin-bottom: 0;

      &.label {
        font-size: 1rem;
        font-weight: 100;

        @media only screen and (max-width: 992px) {
          font-size: 0.9rem;
        }
      }
    }

    .status-text {
      font-size: 1.1rem;
      color: $brand-secondary;

      @media only screen and (max-width: 992px) {
        font-size: 0.9rem;
      }
    }
  }
}
