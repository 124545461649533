@import "./shared.scss";

// body {
//   overflow: hidden;
// }

.btn {
  border: none;
  border-radius: 100px;
  text-transform: uppercase;
  font-weight: 600;

  &.btn-primary:not(:disabled):not(.disabled) {
    background-color: $brand-primary;
    color: $button-text-color;

    &:hover {
      background-color: darken($brand-primary, 5);
    }

    &:active,
    .active {
      background-color: lighten($brand-primary, 20);
    }

    &:focus {
      box-shadow: 0 0 0 0.2rem lighten($brand-primary, 10);
    }
  }

  &.btn-secondary:not(:disabled):not(.disabled) {
    background-color: $brand-secondary;
    color: $button-text-color;

    &:hover {
      background-color: darken($brand-primary, 5);
    }

    &:active,
    .active {
      background-color: lighten($brand-primary, 20);
    }

    &:focus {
      box-shadow: 0 0 0 0.2rem lighten($brand-primary, 10);
    }
  }

  &.btn-dark:not(:disabled):not(.disabled) {
    background-color: $brand-gray;
    color: white;

    &:hover {
      background-color: darken($brand-primary, 5);
    }

    &:active,
    .active {
      background-color: lighten($brand-primary, 20);
    }

    &:focus {
      box-shadow: 0 0 0 0.2rem lighten($brand-primary, 10);
    }
  }

  &.btn-lg {
    padding: 8px 18px;
    font-size: 0.9rem;
  }

  a {
    color: inherit;
    font-weight: 600;
    &:hover {
      color: inherit;
      text-decoration: none;
    }
  }
}

.carousel-caption {
  position: absolute !important;
  top: 35%;
  height: 240px;
  @media screen and (min-width: 391px) and (max-width: 520px) {
    top: 20%;
    height: 477px;
  }
  @media screen and (max-width: 391px) {
    top: 7%;
    height: 456px;
  }
  @media screen and (max-width: 322px) {
    top: 4%;
    height: 395px;
    p {
      font-size: 0.9rem !important;
    }
  }
  h3 {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 2.2rem;
  }
  p {
    font-size: 1.25rem;
    font-weight: 600;
  }
}

.home div {
  height: 100vh;
  min-height: 100vh;
}

.vox-quem-somos {
  div img {
    width: 100px;
  }
  .info-product {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 60px;
  }
}

.vox-como-funciona {
  padding: 50px 0;
  background-image: url("../img/googleassist.png"),
    linear-gradient(to left, $brand-primary, $brand-secondary);
  background-position: right -220px top, 0 0;
  background-repeat: no-repeat;
  background-size: 50% auto, auto auto;
  color: white;

  @media only screen and (max-width: 992px) {
    background-position: right -220px top -50px, 0 0;
    background-size: 75% auto, auto auto;
  }

  @media only screen and (max-width: 700px) {
    background-position: right -200px top -50px, 0 0;
    background-size: 90% auto, auto auto;
  }

  @media only screen and (max-width: 400px) {
    background-position: right -125px top -20px, 0 0;
    background-size: 90% auto, auto auto;
  }

  img {
    width: 250px;
    height: 400px;
    margin: -80px 0 40px;
    transform: rotate(-20deg);

    @media only screen and (max-width: 700px) {
      margin: -80px 0 40px -30px;
    }
  }

  h1 {
    text-transform: uppercase;
    text-align: left;
    font-weight: 600;
    font-size: 2rem;
    line-height: 2.2rem;
    letter-spacing: -0.5px;

    @media only screen and (max-width: 992px) {
      margin-bottom: 30px;
    }
  }

  ul {
    padding-left: 10px;
    text-align: justify;
    list-style-type: none;

    li {
      margin-bottom: 35px;
      font-weight: 300;

      .bullet {
        display: inline-block;
        padding-right: 10px;
        line-height: 1rem;
        font-size: 2rem;
        font-weight: 600;
      }
    }
  }
  .vox-or-ask {
    margin-top: 12px;
    text-transform: uppercase;
    font-weight: 400;
    letter-spacing: 1px;
    line-height: 1.6rem;

    b {
      font-weight: 900;
    }

    .vox-or {
      display: block;
      font-weight: 100;
      margin-bottom: 8px;
    }
  }
}

.vox-acreditamos {
  margin: 20px auto;
  text-transform: uppercase;

  @media only screen and (max-width: 769px) {
    text-align: center;
  }

  img {
    width: 100%;

    @media only screen and (max-width: 769px) {
      width: 50%;
      margin: 0 auto;
    }
  }

  h3 {
    line-height: 2.2rem;
    font-size: 1.5rem;
    font-weight: 300;

    @media only screen and (max-width: 650px) {
      text-align: center;
    }

    b {
      font-weight: 600;
      color: $brand-secondary;
    }
  }
}
.vox-planos-anchor {
  position: absolute;
  height: 100%;
  transform: translateY(0);
  @media only screen and (min-width: 769px) {
    height: 400px;
    width: 100%;
    position: absolute;
    transform: translateY(20px);
  }
  @media only screen and (min-width: 1500px) {
    height: 400px;
    transform: translateY(20px);
  }
}
.vox-planos {
  height: auto;
  padding: 45px 0 0;
  background-color: $brand-secondary;
  color: white;
  text-transform: uppercase;

  &:focus {
    outline: none;
  }

  @media only screen and (max-width: 650px) {
    height: auto;
    text-align: center;
  }

  h3 {
    font-weight: 600;

    b {
      font-weight: 600;
    }
  }

  .vox-itens {
    margin-top: 20px;

    .vox-planos-item {
      float: left;
      width: 33%;
      height: 130px;
      font-size: 0.8rem;
      font-weight: 600;
      line-height: 1rem;
      text-align: center;
      @media screen and (max-width: 991px) {
        height: 160px;
      }

      img {
        display: block;
        width: 60px;
        height: 60px;
        margin: 0 auto 5px;
      }
    }
  }
  .plans-button-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1em;
  }
  .vox-planos-celular {
    height: 440px;
    background: url("../img/phone-white-sm.png") no-repeat 0 0 / auto 420px;
    text-align: center;
    color: $brand-gray;

    .vox-celular-inner {
      width: 188px;
      padding: 70px 0 0 45px;
    }

    @media only screen and (max-width: 650px) {
      margin: 0 auto;
      background: url("../img/phone-white-sm.png") no-repeat center center /
        auto 420px;

      .vox-celular-inner {
        width: 158px;
        margin: 0 auto;
        padding: 90px 0 0 0;
      }
    }

    h4 {
      padding-bottom: 3px;
      border-bottom: 3px solid $brand-gray;
      font-weight: 600;
      letter-spacing: 1.5px;
      font-size: 1rem;
    }

    .apenas {
      padding: 9px 0 14px;
      color: $brand-secondary;
      line-height: 0.9rem;
      vertical-align: middle;
      border-bottom: 3px solid $brand-gray;
      font-weight: 600;

      span {
        display: block;
        font-size: 1.5rem;
        font-weight: 600;
        line-height: 2rem;
      }
    }

    .feature {
      padding: 20px 0;
      margin-bottom: 20px;
      border-bottom: 3px solid $brand-gray;
      font-weight: 600;
      text-transform: none;
      font-size: 0.8rem;
    }

    button {
      width: 100%;
      border-radius: 10px;
      font-size: 0.6rem;
    }
  }
}

.vox-downloads {
  margin-top: 30px;

  h3 {
    font-size: 1.2rem;
    color: $brand-gray;
    text-transform: uppercase;

    strong {
      font-weight: 600;
    }
    b {
      font-weight: 600;
      color: $brand-secondary;
    }
  }
  img {
    width: 153px;
  }
}

.vox-footer {
  padding: 20px 0 30px;
  color: #aaa;
  font-weight: 100;
  font-size: 0.7rem;

  .vox-social-media a {
    display: inline-block;
    width: 30px;
    margin-right: 10px;
    font-size: 1.7rem;
  }

  .vox-social-media a > svg {
    color: $brand-primary;
  }
}

.container-home-content {
  padding-top: 120px;
}

@media only screen and (max-width: 991px) {
  .carousel,
  .item,
  .active,
  #home,
  .carousel,
  .slide,
  .carousel-inner,
  .carousel-item,
  .carousel-item img {
    height: 100%;
    object-fit: cover;
  }
  .carousel-caption {
    h3 {
      font-size: 1.2rem;
    }
    p {
      font-size: 1.1rem;
    }
  }
}

.center-img-carosel {
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: auto !important;
}

.reduce-menu {
  height: auto;
  min-height: 62px !important;
}
.navbar {
  padding: 0.4rem 1rem !important;
}

#home {
  margin-top: 120px;
  @media only screen and (max-width: 992px) {
    margin-top: 66px;
  }
}

.custom-price {
  display: flex !important;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  span {
    &:nth-child(1) {
      font-size: 1.2rem !important;
    }
    &:nth-child(2) {
      font-size: 1rem !important;
      text-transform: lowercase;
    }
  }
}
